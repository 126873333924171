import React from 'react'
import marked from 'marked'

class Markdown extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.rawMarkup = this.rawMarkup.bind(this)
  }
  
  rawMarkup() {
    let markup = marked(this.props.data, {sanitize: true})
    return markup
  }

  render() {
    const markup = this.rawMarkup()
    return <React.Fragment>
        <span dangerouslySetInnerHTML={{ __html: markup}} />
    </React.Fragment>;
  }
}

export default Markdown