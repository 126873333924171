import React from 'react'
import logo from '../assets/images/logo.png'
import Markdown from '../components/Markdown'

const Header = () => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="Heliotech Egypt" /></span>
        <h1>Heliotech</h1>
        <span><p>With more than 26 years as wholesaler and installer of <strong>Fire Alarm</strong>, <strong>Fire Fighting</strong>, <strong>Wet Chemicals</strong> and <strong>CCTv systems</strong>, you are guaranteed the best equipment, experience and after sales service.</p></span>
    </header>
)

export default Header