import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import logo from '../assets/images/logo.png'
import Markdown from '../components/Markdown'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  getImagesPath = (path) => {
    return `/custom/${path}`
  }



  render() {
    const data = {
      internalMetadata: {
        companyAbout: "**Heliotech** Since our establishment in 1993, we've been committed to high quality services and customer satisfaction. With over than 26 years as wholesaler and installers in Egypt & the Middle East for **Fire Alarm**, **Fire Fighting**, **Wet Chemicals**, **Community antennas**, **CCTv** and **Public Address**; you are guaranteed the best equipment, experience and after sales service.",
        heroTitle: 'Heliotech',
        heroSubtext: 'With more than 26 years as wholesaler and installer of **Fire Alarm**, **Fire Fighting**, **Wet Chemical** and **CCTv systems**, you are guaranteed the best equipment, experience and after sales service.',
        siteTitle: '<p>With more than 26 years as wholesaler and installer of <strong>Fire Alarm</strong>, <strong>Fire Fighting</strong>, <strong>Wet Chemical </strong> and <strong>CCTv systems</strong>, you are guaranteed the best equipment, experience and after sales service.</p>',
        address: '17 Nakhla el Motai St. Triumph, Heliopolis, Cairo, Egypt.',
        email: 'sales@heliotech-eg.com',
        phoneNumbers: '(202) 2779300 / (202) 27796250 / (202) 27796400',
        mobile: '01280759222'
      },
      allInternalPartners: [
        {
          node: {
            name: 'Advanced', 
            logo: 'AdvancedLogo.png'
          }
        },
        {
          node: {
            name: 'AMEREX', 
            logo: 'AMEREXlogo-fire.svg'
          }
        }
      ],
  
      allInternalServices: [
        {
          node: {
            icon: 'tint',
            title: 'Water Based Fire Fighting',
            description: 'Sprinkler systems, Fire hydrant systems, Foam systems inline and bladder, and Pump room installations.'
          }
        },
        {
          node: {
            icon: 'fire-extinguisher',
            title: 'Gas Based Fire Fighting',
            description: 'CO<sub>2</sub> systems, FM 200 systems, Wet Chemical systems.'
          }
        },
        {
          node: {
            icon: 'free-code-camp',
            title: 'Kitchen Hood fire Suppression',
            description: 'Design supply and install AMAREX kitchen fire fighting systems.'
          }
        },
        {
          node: {
            icon: 'tv',
            title: 'CCTV',
            description: 'Installation of AHD systems and RG 6, IP systems including CAT 6.'
          }
        },
        {
          node: {
            icon: 'fire',
            title: 'Fire Alarm',
            description: 'Installation of Fire alarm control panels and associated equipment.'
          }
        },
      ]
    };
    return (
      <Layout>
        <Helmet>
          <title>Heliotech Egypt - Firefighting Systems, Fire Alarm Systems, CCTV Systems</title>
          <meta name="description" content="Heliotech, since our establishment in 1993, we've been committed to high quality services and customer satisfaction. With over than 26 years as wholesaler and installers in Egypt & the Middle East for Fire Alarm, Fire Fighting, Community antennas, CCTv and Public Address; you are guaranteed the best equipment, experience and after sales service." />
          <meta name="keywords" content="Heliotech,Hany Behman,Fire Alarm Egypt,Fire Fighting Egypt,Fire Fighting,Ikusi Egypt,Ikusi,Morely-IAS Egypt,Fire Alarm,CCTV Egypt,Public Address Systems,Fire Alarm Installer,Fire Fighting Integrator ,Fire Fighting Installer,Fire Alarm Integrator,Cairo,Egypt,Helio,Tech,Fire Systems,Advanced Systems,Advanced Fire Fighting,Co2,Foam" />
        </Helmet>
        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="company" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Heliotech - The Company</h2>
                </header>
                <Markdown data={data.internalMetadata.companyAbout} />
                {/* <ul className="actions">
                  <li><Link to="/generic" className="button">Learn More</Link></li>
                </ul> */}
              </div>
              <span className="image"><img src={logo} alt="" /></span>
            </div>
          </section>

          <section id="services" className="main special">
            <header className="major">
              <h2>Our Services</h2>

              <p>We specialie in the design, installation and maintainence of the following systems</p>
            </header>

            <ul className="features">
              {data.allInternalServices.map((service, index) => (
              <li key={index}>
                <span className={`icon major style1 fa-${service.node.icon}`}></span>
                <h3>{service.node.title}</h3>
                <p dangerouslySetInnerHTML={{__html: service.node.description}}></p>
              </li>
              ))}
            </ul>
            {/* <footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer> */}
          </section>

          <section id="partners" className="main special">
            <header className="major">
              <h2>Our Partners</h2>
              <p>We partner with the top service and hardware providers </p>
            </header>
            <ul className="features">
              {data.allInternalPartners.map((partner, index) => (
              <li key={index}>
                <img className="major partnerLogo" src={this.getImagesPath(partner.node.logo)} alt={partner.node.name} />
                <h3>{partner.node.name}</h3>
              </li>
              ))}
            </ul>
          </section>

          {/* <section id="cta" className="main special">
            <header className="major">
              <h2>Contact Us</h2>
              <p>Donec imperdiet consequat consequat. Suspendisse feugiat congue<br />
              posuere. Nulla massa urna, fermentum eget quam aliquet.</p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button special">Get Started</Link></li>
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer>
          </section> */}

        </div>
        <Footer metadata={data.internalMetadata} />    
      </Layout>
    )
  }
}

// export const data = graphql`
// {
//   allInternalServices(filter: {title: {ne: null}}) { 
//     edges {
//       node { 
//         title
//         description
//         icon
//       }
//     }
//   }
//   allInternalPartners(filter: {name: {ne: null}}) { 
//     edges {
//       node { 
//         name
//         logo
//       }
//     }
//   }
//   internalMetadata {
//     heroTitle
//     heroSubtext
//     companyAbout
//     keywords
//     siteTitle
//     address
//     email
//     phoneNumbers
//     fax
//   }
// }
// `

export default Index
