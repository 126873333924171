import React from 'react'

const year = new Date().getFullYear();

const Footer = ({ metadata }) => (
    <footer id="footer">
        <section>
            <h2>Technical Support</h2>
            <p>For technical support please <a href="mailto:tech.support@heliotech-eg.com">email us</a> and our technical support team will contact you as soon as possible.</p>
            {/* <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
            </ul> */}
        </section>
        <section>
            <h2>Head Quarters</h2>
            <dl className="alt">
                <dt>Address</dt>
                <dd>{metadata.address}</dd>
                <dt>Phone</dt>
                <dd>{metadata.phoneNumbers}</dd>
                <dt>Mobile</dt>
                <dd><a href={`tel:${metadata.mobile}`}>{metadata.mobile}</a></dd>
                <dt>Email</dt>
                <dd><a href={`mailto:${metadata.email}`}>{metadata.email}</a></dd>
            </dl>
            {/* <ul className="icons">
                <li><a href="#" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon fa-facebook alt"><span className="label">Facebook</span></a></li>
                <li><a href="#" className="icon fa-instagram alt"><span className="label">Instagram</span></a></li>
                <li><a href="#" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
                <li><a href="#" className="icon fa-dribbble alt"><span className="label">Dribbble</span></a></li>
            </ul> */}
        </section>
        <p className="copyright">Copyrights &copy; {year} {metadata.heroTitle}.</p>
    </footer>
)

export default Footer
