import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['company', 'services', 'partners'] } currentClassName="is-active" offset={-300}>
            <li>
                <Scroll type="id" element="company">
                    <a href="#company">About Us</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="services">
                    <a href="#services">Our Services</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="partners">
                    <a href="#partners">Our Partners</a>
                </Scroll>
            </li>
            {/* <li>
                <Scroll type="id" element="cta">
                    <a href="#">Contact Us</a>
                </Scroll>
            </li> */}
        </Scrollspy>
    </nav>
)

export default Nav
